import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withDefaultLayoutAndGlobals } from '../layouts/TemplateBase.jsx';
import { valueHasData } from '../../utils/data';
import RawMarkup from '../elements/RawMarkup.jsx';
import ConditionalRender from '../elements/ConditionalRender.jsx';
import Carousel from '../elements/Carousel/index.jsx';
import GatedContentWrapper from './standard_interior/GatedContentWrapper.jsx';
import Nav from '../elements/Nav/index.jsx';
import PageEyebrow from '../elements/PageEyebrow.jsx';
import PlainHeader from '../elements/PlainHeader/index.jsx';
import Repeater from '../elements/Repeater.jsx';
import VutureEmbed from './standard_interior/VutureEmbed.jsx';
import SidebarLinks from '../elements/SidebarLinks.jsx';
import PeopleListings from '../elements/PeopleListing/PeopleListings.jsx';
import Resources from '../elements/Resources/index.jsx';
import Highlights from './standard_interior/Highlights.jsx';
import Listings from '../elements/Listings/index.jsx';
import Statistics from '../elements/Statistics/index.jsx';
import styles from './career_landing/career-landing.scss';

class StandardTemplate extends React.Component {
    static getRepeaterPropsForType(type) {
        const repeaterTypeProps = {
            captioned_media: {
                extraClasses: [
                    'bg-off-white',
                ],
            },
        };

        return repeaterTypeProps[type] || {};
    }

    hasSidebarLinks() {
        const sidebarLinks = this.props.sidebarLinks && this.props.sidebarLinks.links;
        return valueHasData(sidebarLinks);
    }

    render() {
        const { carousel } = this.props;
        return (
            <React.Fragment>
                <PlainHeader
                    color="medium-blue"
                    {...this.props.headerProps}
                />
                <Nav {...this.props.topNav} window={this.props.window} />
                <div className="l-page-container  bg-white">
                    <PageEyebrow
                        breadcrumbLinks={this.props.breadcrumbLinks}
                        utilityNavProps={this.props.utilityNavProps}
                        hidePDF
                    />

                    <main className="u-margin-top-small">
                        <ConditionalRender condition={!_.isEmpty(this.props.carouselSlides)}>
                            <div className="l-standard-width u-margin-bottom-large">
                                <Carousel slides={this.props.carouselSlides} />
                            </div>
                        </ConditionalRender>
                        {
                          carousel && carousel.length > 0 &&
                          <div className="l-standard-width u-margin-bottom-large">
                            <Carousel slides={carousel} />
                          </div>
                        }
                        <section className="l-standard-width l-2-columns u-margin-bottom-med">
                            <div className="l-1m-1t-2d-wide-column-item">
                                <ConditionalRender condition={this.props.main_content}>
                                    <div className="rte">
                                        <RawMarkup>
                                            {this.props.main_content}
                                        </RawMarkup>
                                    </div>
                                </ConditionalRender>

                                <ConditionalRender condition={this.props.show_gated_form}>
                                    <GatedContentWrapper
                                        gated_content_title={this.props.gated_content_title}
                                        gated_content_header={this.props.gated_content_header}
                                        gated_content_main={this.props.gated_content_main}
                                        gated_content_success_message={this.props.gated_content_success_message}
                                        recaptchaKey={this.props.recaptchaKey}
                                        pageId={this.props.id}
                                    />
                                </ConditionalRender>
                                {this.props.repeaterData.map((repeaterEntry, idx) => (
                                    <div className="u-padding-top-med u-reset-first-child-whitespace" key={idx}>
                                        <Repeater
                                            type={repeaterEntry.type}
                                            props={{
                                                hasSidebar: true,
                                                ...StandardTemplate.getRepeaterPropsForType(repeaterEntry.type),
                                                ...repeaterEntry.props,
                                            }}
                                        />
                                    </div>
                                ))}

                                <VutureEmbed {...this.props.vutureProps} />
                            </div>
                            <ConditionalRender condition={this.hasSidebarLinks() || valueHasData(this.props.peopleListings)}>
                                <aside className="l-1m-1t-2d-small-column-item">
                                    <ConditionalRender condition={this.hasSidebarLinks()}>
                                        <div className="u-page-break-inside-avoid">
                                            <SidebarLinks {...this.props.sidebarLinks} border="blue" firstItemBorder={1} />
                                        </div>
                                    </ConditionalRender>

                                    <ConditionalRender condition={valueHasData(this.props.peopleListings)}>
                                        <div className="u-padding-top-xlarge u-reset-first-child-whitespace u-page-break-inside-avoid clearfix">
                                            <PeopleListings
                                                title="Contacts"
                                                tag="h4"
                                                people={this.props.peopleListings}
                                            />
                                        </div>
                                    </ConditionalRender>
                                </aside>
                            </ConditionalRender>
                        </section>
                        <section>
                            <ConditionalRender condition={valueHasData(this.props.resources)}>
                                <div className="l-standard-width u-padding-med bg-dark-blue u-page-break-inside-avoid">
                                    <Resources
                                        title="Resources"
                                        resources={this.props.resources}
                                        extraClasses={[
                                            'type-results-header',
                                            'white',
                                            'u-margin-bottom-med',
                                        ]}
                                    />
                                </div>
                            </ConditionalRender>
                            <ConditionalRender condition={this.props.highlights.length}>
                                <div className="l-standard-width u-margin-top-med u-margin-bottom-med u-page-break-inside-avoid">
                                    <Highlights title="Highlights" highlights={this.props.highlights} />
                                </div>
                            </ConditionalRender>

                            <ConditionalRender condition={this.props.relatedInsights.length}>
                                <div className="l-full-width-mobile u-margin-top-med u-margin-bottom-med u-page-break-inside-avoid">
                                    <Listings
                                        title="Related Insights"
                                        listings={this.props.relatedInsights}
                                        titleExtraClasses={[
                                            'u-padding-mobile-only',
                                        ]}
                                        url={this.props.relatedInsightsUrl}
                                        viewAllText={this.props.relatedInsightsText || 'View Related Insights'}
                                    />
                                </div>
                            </ConditionalRender>
                        </section>
                        <section>
                            <ConditionalRender condition={valueHasData(this.props.stats)}>
                                <Statistics stats={this.props.stats}/>
                            </ConditionalRender>
                        </section>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}

StandardTemplate.propTypes = {
    headerProps: PropTypes.shape(PlainHeader.propTypes),
    topNav: PropTypes.shape(Nav.propTypes),
    breadcrumbLinks: PageEyebrow.propTypes.breadcrumbLinks,
    utilityNavProps: PageEyebrow.propTypes.utilityNavProps,
    repeaterData: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    stats: PropTypes.arrayOf(PropTypes.shape({
        stat: PropTypes.string,
        text: PropTypes.string,
    })),
    vutureProps: PropTypes.shape(VutureEmbed.propTypes),
    peopleListings: PeopleListings.propTypes.people,
    sidebarLinks: PropTypes.shape(SidebarLinks.propTypes),
    resources: Resources.propTypes.resources,
    relatedInsightsUrl: PropTypes.string,
    relatedInsightsText: PropTypes.string,
    displayNameHTML: PropTypes.string,
    main_content: PropTypes.string,
    highlights: Highlights.propTypes.highlights,
    relatedInsights: Listings.propTypes.listings,
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    carouselSlides: Carousel.propTypes.slides,
    show_gated_form: PropTypes.bool,
    gated_content_title: PropTypes.string,
    gated_content_header: PropTypes.string,
    gated_content_main: PropTypes.string,
    gated_content_success_message: PropTypes.string,
    recaptchaKey: PropTypes.string,
    id: PropTypes.number,
    carousel: Carousel.propTypes.slides,
};

StandardTemplate.defaultProps = {
    headerProps: {},
    topNav: {},
    breadcrumbLinks: [],
    utilityNavProps: {},
    repeaterData: [],
    stats: [],
    vutureProps: {},
    peopleListings: [],
    sidebarLinks: {},
    resources: [],
    relatedInsightsUrl: '',
    relatedInsightsText: '',
    displayNameHTML: '',
    main_content: '',
    highlights: Highlights.defaultProps.highlights,
    relatedInsights: Listings.defaultProps.listings,
    carouselSlides: Carousel.defaultProps.slides,
    show_gated_form: false,
    gated_content_title: '',
    gated_content_header: '',
    gated_content_main: '',
    gated_content_success_message: '',
    recaptchaKey: '',
    id: null,
    carousel: Carousel.defaultProps.slides,
};

export default withDefaultLayoutAndGlobals(StandardTemplate);
